import { rest } from 'msw';
import { getUrl, makeResponse } from '.';

export const userHandlers = [
  rest.post(getUrl('user/sms'), (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(
        makeResponse({
          msg: '短信发送成功',
          data: {
            accessToken: 'test-token',
            refreshToken: 'test-token',
          },
        }),
      ),
    );
  }),
  rest.post(getUrl('user/login'), (req, res, ctx) => {
    sessionStorage.setItem('isAuthorizated', 'true');

    return res(
      ctx.status(200),
      ctx.json(
        makeResponse({
          data: {
            accessToken: 'test-token',
            refreshToken: 'test-token',
          },
        }),
      ),
    );
  }),
  rest.post(getUrl('user/sms_login'), (req, res, ctx) => {
    sessionStorage.setItem('isAuthorizated', 'true');

    return res(
      ctx.status(200),
      ctx.json(
        makeResponse({
          data: {
            accessToken: 'test-token',
            refreshToken: 'test-token',
          },
        }),
      ),
    );
  }),
  rest.get(getUrl('user/profile'), (req, res, ctx) => {
    const isAuthorizated = sessionStorage.getItem('isAuthorizated');
    if (isAuthorizated !== 'true') {
      return res(ctx.status(401));
    }
    return res(
      ctx.status(200),
      ctx.json(
        makeResponse({
          data: {
            username: 'deltron-admin',
            mobile: '13710001010',
            permission: [
              {
                id: 1,
                parentId: 1,
                key: 'login',
                name: '登录',
                type: '0',
              },
            ],
          },
        }),
      ),
    );
  }),
];
