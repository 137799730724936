import React from 'react';
import {
  AimOutlined,
  ClusterOutlined,
  LineChartOutlined,
  SettingOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { IRoute } from '@deltron/common/router';

export const ROUTES = {
  NOT_FOUND: 'notFound',
  NOT_AUTHORIZED: 'notAuthorized',
  LOGIN: 'login',
  RESET_PASSWORD: 'resetPassword',
  DASHBOARD: 'dashboard',
  DEVICE_MAP: 'deviceMap',
  BUSINESS_MANAGE: 'businessManage',
  COMPANY_MANAGE: 'companyManage',
  COMPANY_LIST: 'companyList',
  COMPANY_LIST_READONLY: 'companyList:readonly',
  COMPANY_DETAIL: 'companyDetail',
  CLIENT_MANAGE: 'clientManage',
  CLIENT_LIST: 'clientList',
  CLIENT_LIST_READONLY: 'clientList:readonly',
  CLIENT_DETAIL: 'clientDetail',
  CLIENT_AUDIT: 'clientAudit',
  CLIENT_AUDIT_RECORD: 'clientAuditRecord',
  DEVICE_MANAGE: 'deviceManage',
  DEVICE_LIST: 'deviceList',
  DEVICE_LIST_READONLY: 'deviceList:readonly',
  DEVICE_DETAIL: 'deviceDetail',
  DEVICE_BATCH_DETAIL: 'deviceBatchDetail',
  DEVICE_EDIT: 'deviceEdit',
  USER_MANAGE: 'userManage',
  USER_LIST: 'userList',
  USER_DETAIL: 'userDetail',
  ROLE_MANAGE: 'roleManage',
  PAGE_NOT_FOUND: 'pageNotFound',
  PROFILE: 'profile',
  DATA_ANALYSIS: 'dataAnalysis',
  DATA_STATISTICS: 'dataStatistics',
};

const routes: IRoute[] = [
  {
    key: ROUTES.LOGIN,
    path: '/login',
    component: React.lazy(() => import('../features/User/Login/Login')),
    nav: false,
  },
  {
    key: ROUTES.RESET_PASSWORD,
    path: '/reset-password',
    component: React.lazy(
      () => import('../features/User/ResetPassword/ResetPassword'),
    ),
    nav: false,
  },
  {
    key: ROUTES.DASHBOARD,
    path: '/dashboard',
    component: React.lazy(() => import('../layouts/AdminDashboardLayout')),
    nav: false,
    meta: {
      title: '道创云',
    },
    children: [
      {
        key: ROUTES.NOT_AUTHORIZED,
        path: '/403',
        component: React.lazy(
          () => import('@deltron/common/pages/Unauthorized'),
        ),
        nav: false,
      },
      {
        key: ROUTES.DEVICE_MAP,
        nav: true,
        path: '/device-map',
        meta: {
          title: '设备地图',
          icon: <AimOutlined />,
        },
        component: React.lazy(
          () => import('../features/Dashboard/DeviceMap/DeviceMap'),
        ),
      },
      {
        key: ROUTES.BUSINESS_MANAGE,
        path: '/business',
        meta: {
          title: '业务管理',
          icon: <ClusterOutlined />,
        },
        nav: true,
        children: [
          {
            key: ROUTES.COMPANY_MANAGE,
            group: true,
            nav: true,
            path: '/company',
            meta: {
              title: '公司管理',
              icon: <ClusterOutlined />,
            },
            children: [
              {
                key: ROUTES.COMPANY_LIST,
                path: '/',
                component: React.lazy(
                  () => import('../features/Dashboard/Company/CompanyManage'),
                ),
                meta: {
                  title: '公司列表',
                },
                nav: true,
                children: [
                  {
                    key: ROUTES.COMPANY_DETAIL,
                    path: ':id',
                    component: React.lazy(
                      () =>
                        import('../features/Dashboard/Company/CompanyDetails'),
                    ),
                    meta: {
                      title: '公司详情',
                    },
                    nav: false,
                  },
                ],
              },
            ],
          },
          {
            key: ROUTES.CLIENT_MANAGE,
            group: true,
            nav: true,
            path: '/client',
            meta: {
              title: '客户管理',
              icon: <ClusterOutlined />,
            },
            children: [
              {
                key: ROUTES.CLIENT_AUDIT,
                path: '/audit',
                component: React.lazy(
                  () => import('../features/Dashboard/Client/ClientAudit'),
                ),
                meta: {
                  title: '客户审核',
                },
                nav: true,
                children: [
                  {
                    key: ROUTES.CLIENT_AUDIT_RECORD,
                    parentKey: ROUTES.CLIENT_AUDIT,
                    path: '/record',
                    component: React.lazy(
                      () => import('../features/Dashboard/Client/AuditRecord'),
                    ),
                    meta: {
                      title: '审核记录',
                    },
                    nav: false,
                  },
                ],
              },
              {
                key: ROUTES.CLIENT_LIST,
                path: '/manage/',
                component: React.lazy(
                  () => import('../features/Dashboard/Client/ClientManage'),
                ),
                meta: {
                  title: '客户列表',
                },
                nav: true,
                children: [
                  {
                    key: ROUTES.CLIENT_DETAIL,
                    parentKey: ROUTES.CLIENT_LIST,
                    path: ':id',
                    component: React.lazy(
                      () =>
                        import('../features/Dashboard/Client/ClientDetails'),
                    ),
                    meta: {
                      title: '客户详情',
                    },
                    nav: false,
                  },
                ],
              },
            ],
          },
          {
            key: ROUTES.DEVICE_MANAGE,
            path: '/device',
            meta: {
              title: '设备管理',
            },
            nav: true,
            group: true,
            children: [
              {
                key: ROUTES.DEVICE_LIST,
                path: '/manage/',
                component: React.lazy(
                  () => import('../features/Dashboard/Device/DeviceManage'),
                ),
                meta: {
                  title: '设备列表',
                },
                nav: true,
                children: [
                  {
                    key: ROUTES.DEVICE_DETAIL,
                    parentKey: ROUTES.DEVICE_LIST,
                    path: ':serialNumber',
                    component: React.lazy(
                      () =>
                        import('../features/Dashboard/Device/DeviceDetails'),
                    ),
                    meta: {
                      title: '设备详情',
                    },
                    nav: false,
                  },
                  {
                    key: ROUTES.DEVICE_BATCH_DETAIL,
                    path: ':serialNumber/report/:id',
                    component: React.lazy(
                      () =>
                        import('../features/Dashboard/Report/ReportDetails'),
                    ),
                    meta: {
                      title: '批次详情',
                    },
                    nav: false,
                  },
                ],
              },
              {
                key: ROUTES.DEVICE_EDIT,
                path: '/edit/:serialNumber?',
                component: React.lazy(
                  () => import('../features/Dashboard/Device/DeviceEdit'),
                ),
                meta: {
                  title: '',
                },
                nav: false,
              },
            ],
          },
        ],
      },
      {
        key: ROUTES.DATA_ANALYSIS,
        path: '/data',

        meta: {
          title: '数据分析',
          icon: <LineChartOutlined />,
        },
        nav: true,
        children: [
          {
            key: ROUTES.DATA_STATISTICS,
            path: '/statistics',
            component: React.lazy(
              () => import('../features/Dashboard/DataAnalysis/DataStatistics'),
            ),
            meta: {
              title: '数据统计',
            },
            nav: true,
          },
        ],
      },
      {
        key: ROUTES.USER_MANAGE,
        path: '/user',
        meta: {
          title: '用户管理',
          icon: <UserOutlined />,
        },
        nav: true,
        children: [
          {
            key: ROUTES.USER_LIST,
            path: '/manage/',
            component: React.lazy(
              () => import('../features/Dashboard/User/UserManage'),
            ),
            meta: {
              title: '用户列表',
            },
            nav: true,
            children: [
              {
                parentKey: ROUTES.USER_LIST,
                key: ROUTES.USER_DETAIL,
                path: ':id',
                component: React.lazy(
                  () => import('../features/Dashboard/User/UserDetails'),
                ),
                meta: {
                  title: '用户详情',
                },
                nav: false,
              },
            ],
          },
          {
            key: ROUTES.ROLE_MANAGE,
            path: '/role/',
            component: React.lazy(
              () => import('../features/Dashboard/User/RoleManage'),
            ),
            meta: {
              title: '角色列表',
            },
            nav: true,
          },
        ],
      },
      {
        key: ROUTES.PROFILE,
        path: '/profile',
        component: React.lazy(
          () => import('../features/Dashboard/Settings/Profile'),
        ),
        meta: {
          title: '账户设置',
          icon: <SettingOutlined />,
        },
        nav: true,
      },
      {
        key: ROUTES.PAGE_NOT_FOUND,
        path: '/*',
        auth: false,
        component: React.lazy(() => import('@deltron/common/pages/NotFound')),
        nav: false,
      },
    ],
  },
];

export default routes;
