import { AppearanceContext, AuthContext } from '@app/context';
import request, { AxiosContext } from '@deltron/common/services/request';
import React, { useMemo, useRef, useState } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import './App.less';

import queryString from 'query-string';
import { useHistory } from 'react-router-dom';
import locale from 'antd/es/locale/zh_CN';
import 'dayjs/locale/zh-cn';
import { ConfigProvider } from 'antd';
import storageService from '@deltron/common/storage';
import { Permission } from '@interfaces/permission';
import AppRouter from './router';

const queryClient = new QueryClient({});

const AxiosProvider = ({ children }: React.PropsWithChildren<unknown>) => {
  const axiosValue = useMemo(() => {
    return request;
  }, []);

  return (
    <AxiosContext.Provider value={axiosValue}>{children}</AxiosContext.Provider>
  );
};

function App(): JSX.Element {
  const [isLogged, setIsLogged] = useState(
    () => storageService.getAccessToken() !== '',
  );
  const [permissionList, setPermissionList] = useState<Permission[]>([]);
  const [pageTitle, setPageTitle] = useState('');
  const [pageKey, setPageKey] = useState('');
  const [deviceSerialNumber, setDeviceSerialNumber] = useState<
    string | undefined
  >();
  const pageHeaderExtra = useRef<React.ReactNode | undefined>();
  const history = useHistory();

  return (
    <AppearanceContext.Provider
      value={{
        pageTitle,
        pageHeaderExtra,
        pageKey,
        setPageTitle: (value: string) => {
          setPageTitle(value);
        },
        setPageKey: (value: string) => {
          setPageKey(value);
        },
        setPageHeaderExtra: (extra: React.ReactNode) => {
          pageHeaderExtra.current = extra;
        },
      }}
    >
      <AuthContext.Provider
        value={{
          isLogged,
          deviceSerialNumber,
          permissionList,
          setPermissionList: (list) => setPermissionList(list),
          selectDevice: (serialNumber) => setDeviceSerialNumber(serialNumber),
          login: () => {
            setIsLogged(true);

            const values = queryString.parse(history.location.search) as {
              redirectUrl: string;
            };

            if (values.redirectUrl) {
              history.push(values.redirectUrl);
            } else {
              history.push('/dashboard/device-map');
            }
          },
          logout: () => {
            storageService.clearTokens();
            setIsLogged(false);
          },
        }}
      >
        <AxiosProvider>
          <QueryClientProvider client={queryClient}>
            <ConfigProvider locale={locale}>
              <AppRouter />
            </ConfigProvider>
          </QueryClientProvider>
        </AxiosProvider>
      </AuthContext.Provider>
    </AppearanceContext.Provider>
  );
}

export default App;
